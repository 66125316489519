@tailwind base;
@tailwind components;
@tailwind utilities;

* {
    -moz-user-select: none;
    user-select: none;
}

html,
body {
    overscroll-behavior: none;
}

/* width */
::-webkit-scrollbar {
    width: 15px;
}

/* Track */
::-webkit-scrollbar-track {
    background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 8px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: #555;
}